import { createActions } from 'redux-actions';

const actionCreators: any = createActions({
    appStatus: {
        toggleNavBar: undefined,
        setFeatureTabIndex: undefined,
        setShowTemplates: undefined,
    },
    auth: {
        setToken: undefined,
    },
    store: {
        setAll: undefined,
        setTheme: undefined,
        addImpressum: undefined,
        updateImpressum: undefined,
        deleteImpressum: undefined,
        createProduct: undefined,
        updateProduct: undefined,
        deleteProduct: undefined,
        addCompany: undefined,
        updateCompany: undefined,
        deleteCompany: undefined,
        sortCompanies: undefined,
        createReview: undefined,
        updateReview: undefined,
        deleteReview: undefined,
        addReservationSettings: undefined,
        updateReservationSettings: undefined,
        addCateringSettings: undefined,
        updateCateringSettings: undefined,
        addEventSettings: undefined,
        updateEventSettings: undefined,
        updatePopup: undefined,
        deletePopup: undefined,
        updateButton: undefined,
    },
    user: {
        setAll: undefined,
    },
});

export default actionCreators;
