import { Form, Button, Input } from 'antd';
import { useState } from 'react';
import { StoreInterface } from '../../../exports/Interfaces';
import useRdxStore from '../../../hooks/useRdxStore';
import CustomForm from '../../forms/CustomForm';
import SectionLayout from '../../layouts/SectionLayout';
import StoreManager from '../../../services/api/StoreManager';

const { TextArea } = Input;

function InjectCodeForm(): JSX.Element {
    const { store } = useRdxStore();

    const [form1] = Form.useForm();

    const [form2] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = async (values: Partial<StoreInterface>): Promise<void> => {
        setLoading(true);
        await StoreManager.put(store?.id, values);
        setLoading(false);
    };

    return (
        <SectionLayout title="Inject Code">
            <CustomForm className="mb-large" title="Head">
                <Form
                    form={form1}
                    initialValues={{
                        remember: true,
                        ...store,
                    }}
                    layout="vertical"
                >
                    <div className="mb-small">
                        <p>{'<meta>'}</p>
                        <Form.Item name="google_site_verification" label="Google Site Verification" className="mb-none">
                            <Input />
                        </Form.Item>
                        <p>{'<meta>'}</p>
                    </div>
                    <div className="mb-small">
                        <p>{'<script>'}</p>
                        <Form.Item name="head_scripts" className="mb-none">
                            <TextArea rows={20} />
                        </Form.Item>
                        <p>{'</script>'}</p>
                    </div>
                    <Form.Item className="mb-none">
                        <Button type="primary" loading={loading} onClick={() => onFinish(form1.getFieldsValue())}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </CustomForm>
            <CustomForm title="Body">
                <Form
                    form={form2}
                    initialValues={{
                        remember: true,
                        ...store,
                    }}
                    layout="vertical"
                >
                    <p className="text-brand-text-grey mb-small">Inject code immediately after opening body</p>
                    <div className="mb-small">
                        <p>{'<noscripts>'}</p>
                        <Form.Item name="body_noscripts" className="mb-none">
                            <TextArea rows={20} />
                        </Form.Item>
                        <p>{'</noscripts>'}</p>
                    </div>

                    <Form.Item className="mb-none">
                        <Button type="primary" loading={loading} onClick={() => onFinish(form2.getFieldsValue())}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </CustomForm>
        </SectionLayout>
    );
}

export default InjectCodeForm;
