import { Form, Input, Button, Drawer } from 'antd';
import collect from 'collect.js';
import { useState } from 'react';
import { CompanyInterface, MediaInterface } from '../../exports/Interfaces';
import CompanyManager from '../../services/api/CompanyManager';
import useScreenType from '../../hooks/useScreenType';
import SelectedMediaList from '../media/SelectedMediaList';
import { DB_MODELS, MEDIA_TAGS, MEDIA_TYPES } from '../../exports/Enums';
import MediaManager from '../../services/api/MediaManager';

interface Props {
    open: boolean;
    company?: CompanyInterface | null;
    toggle: (open?: boolean) => void;
}

function CompanyModal(props: Props): JSX.Element {
    const { open, company, toggle } = props;

    const isUpdate = !!company;

    const { isDesktop } = useScreenType();

    const [loading, setLoading] = useState<boolean>(false);

    const [mediaList, setMediaList] = useState<MediaInterface[]>([]);

    const onFinish = async (values: any): Promise<void> => {
        setLoading(true);
        const data = collect(values)
            ?.filter((value) => value !== undefined)
            .all() as Partial<CompanyInterface>;
        let request;
        if (typeof company?.id === 'number') {
            request = await CompanyManager.put(company?.id, data);
        } else {
            request = await CompanyManager.post(data);
        }

        setLoading(false);

        const { response, success } = request;

        if (success && typeof response?.data?.data?.id === 'number') {
            await MediaManager.post(DB_MODELS.COMPANY, response.data.data.id, mediaList, MEDIA_TAGS.IMAGES, '');
            toggle();
        }
    };

    return (
        <Drawer
            title={isUpdate ? 'Update Company' : 'Add Company'}
            open={open}
            onClose={() => toggle(false)}
            footer={null}
            width={isDesktop ? window.innerWidth * 0.3 : window.innerWidth}
        >
            <Form
                name="basic"
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                    ...company,
                }}
            >
                <div className="mb-small w-full">
                    <SelectedMediaList
                        uploadCount={1}
                        mediaType={MEDIA_TYPES.IMAGE}
                        defaultMediaList={company?.images || []}
                        mediaList={mediaList}
                        setMediaList={setMediaList}
                    />
                </div>
                <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please add a company name' }]}>
                    <Input placeholder="Enter Name" />
                </Form.Item>

                <Form.Item name="google_places_id" label="Google Place Id">
                    <Input placeholder="Enter Google Place Id" />
                </Form.Item>

                <Form.Item name="phone_number" label="Phone Number">
                    <Input placeholder="Enter Phone Number" />
                </Form.Item>

                <Form.Item className="mb-none">
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
}

export default CompanyModal;
