export enum REDUX_STORE_SLICES {
    APP_STATUS = 'appStatus',
    AUTH = 'auth',
    STORE = 'store',
    MERCHANT = 'user',
    ONBOARDING = 'onboarding',
}

export enum ENVIRONMENTS {
    DEV = 'development',
    STAGING = 'staging',
    PROD = 'production',
}

export enum SCREEN_TYPES {
    DESKTOP = 'Desktop',
    TABLET = 'Tablet',
    MOBILE = 'Mobile',
}

export enum SECTION_TYPES {
    HERO_SECTION = 'hero_section',
    CUSTOMIZABLE_SECTION = 'customizable_section',
    MENU_SECTION = 'menu_section',
    GALLERY_SECTION = 'gallery_section',
    LOCATION_TRACKER_SECTION = 'location_tracker_section',
    GOOGLE_REVIEW_SECTION = 'google_review_section',
    PRODUCT_SECTION = 'product_section',
    YOUTUBE_SECTION = 'youtube_section',
    IFRAME_SECTION = 'iframe_section',
    ANIMATED_SECTION = 'animated_section',
    TIME_LINE_SECTION = 'time_line_section',
    FAQ_SECTION = 'faq_section',
}

export enum HERO_SECTION_TYPES {
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FOUR = 4,
    FIVE = 5,
    SIX = 6,
    SEVEN = 7,
    EIGHT = 8,
    NINE = 9,
    TEN = 10,
    ELEVEN = 11,
    TWELVE = 12,
    THIRTEEN = 13,
    FOURTEEN = 14,
    FIFTEEN = 15,
    SIXTEEN = 16,
    SEVENTEEN = 17,
    EIGHTEEN = 18,
}

export enum GALLERY_SECTION_TYPES {
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FOUR = 4,
}

export enum CUSTOMIZABLE_SECTION_TYPES {
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FOUR = 4,
    FIVE = 5,
    SIX = 6,
    SEVEN = 7,
    EIGHT = 8,
    NINE = 9,
    TEN = 10,
    ELEVEN = 11,
    TWELVE = 12,
    THIRTEEN = 13,
    FOURTEEN = 14,
    FIFTEEN = 15,
    SIXTEEN = 16,
    SEVENTEEN = 17,
    EIGHTEEN = 18,
    NINETEEN = 19,
    TWENTY = 20,
    TWENTY_ONE = 21,
    TWENTY_TWO = 22,
    TWENTY_THREE = 23,
    TWENTY_FOUR = 24,
    TWENTY_FIVE = 25,
    TWENTY_SIX = 26,
    TWENTY_SEVEN = 27,
    TWENTY_EIGHT = 28,
    TWENTY_NINE = 29,
    THIRTY = 30,
}

export enum PRODUCT_SECTION_TYPES {
    ONE = 1,
    TWO = 2,
}

export enum ANIMATED_SECTION_TYPES {
    ONE = 1,
    TWO = 2,
}

export enum MENU_SECTION_TYPES {
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FOUR = 4,
    FIVE = 5,
    SIX = 6,
    SEVEN = 7,
    EIGHT = 8,
}

export enum FAQ_SECTION_TYPES {
    ONE = 1,
}

export enum TIME_LINE_SECTION_TYPES {
    ONE = 1,
}

export enum PAGES {
    LOGIN = '/login',
    LAYOUT = '/layout',
    RESTAURANT_INFO = '/restaurant-info',
    THEME = '/theme',
    RESERVATIONS = '/reservations',
    PLUGINS = '/plugins',
}

export enum SUB_PAGES {
    ADD_SECTION = '/add-section',
    NAVIGATION_BAR = '/navigation-bar',
    NAVIGATION_ITEMS = '/navigation-items',
    NEWS_BANNER = '/news-banner',
    GENERAL_INFORMATION = '/general-information',
    LINKS = '/links',
    SETTINGS = '/settings',
    ORDER_METHODS = '/order-methods',
    PRODUCTS = '/products',
    GOOGLE_REVIEWS = '/google-reviews',
    IMPRESSUMS = '/impressums',
    SEO = '/seo',
    OPENING_HOURS = '/opening-hours',
    LOCATIONS = '/locations',
    LOGO = '/logo',
    STYLE = '/style',
    COMPANIES = '/companies',
    RESERVATION = '/reservation',
    CATERING = '/catering',
    EVENTS = '/events',
    POPUPS = '/popups',
    ABOUT_US = '/about-us',
    SECTION = '/section',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    PAGES = '/pages',
    HOME = '/home',
    TEMPLATES = '/templates',
    FONTS = '/fonts',
    COLORS = '/colors',
    PAGE_LOADING = '/page-loading',
    BUTTONS = '/buttons',
    TYPOGRAPHY = '/typography',
    FOOTER = '/footer',
    INJECT_CODE = '/inject-code',
    FLOATING_ICON = '/floating-icon',
}

export enum ONBOARDING_PAGES {
    SEO = '/seo',
    ORDER_METHODS = '/order-methods',
    SOCIAL = '/social',
    GOOGLE_REVIEWS = '/google-reviews',
    CATERING = '/catering',
    EVENTS = '/events',
    ABOUT_US = '/about-us',
    HOME = '/home',
    SECTIONS = '/sections',
    OVERVIEW = '/overview',
    NAVIGATION = '/navigation',
    MENUS = '/menus',
}

export enum PAGE_NAMES {
    LAYOUT = 'Layout',
    RESTAURANT_INFORMATION = 'Restaurant Information',
    PLUGINS = 'Plugins',
    THEME = 'Theme',
    MEDIA = 'Media',
    LOGOUT = 'Logout',
}

export enum SUB_PAGE_NAMES {
    NAVIGATION_BAR = 'Navigation Bar',
    NAVIGATION_ITEMS = 'Navigation Items',
    NEWS_BANNER = 'News',
    GENERAL_INFORMATION = 'General Information',
    LINKS = 'Links',
    SETTINGS = 'Settings',
    ORDER_METHODS = 'Order Methods',
    PRODUCTS = 'Products',
    COMPANIES = 'Companies',
    GOOGLE_REVIEWS = 'Google Reviews',
    OPENING_HOURS = 'Opening Hours',
    LOCATIONS = 'Locations',
    IMPRESSUMS = 'Impressums',
    SEO = 'SEO',
    LOGO = 'Logo',
    FLOATING_ICON = 'Floating Icon',
    STYLE = 'Style',
    PAGE_LOADING = 'Page Loading',
    TEMPLATES = 'Templates',
    NAVIGATION = 'Navigation',
    RESERVATIONS = 'Reservations',
    CATERING = 'Catering',
    EVENTS = 'Events',
    POPUPS = 'Popups',
    HOME = 'Home',
    ABOUT_US = 'About Us',
    FONTS = 'Fonts',
    COLORS = 'Colors',
    TYPOGRAPHY = 'Typography',
    BUTTONS = 'Buttons',
    FOOTER = 'Footer',
    SECTIONS = 'Sections',
    INJECT_CODE = 'Inject Code',
}

export enum STOREFRONT_PAGES {
    HOME = '/',
    IMPRESSUM = '/impressum',
    PRIVACY_POLICY = '/privacy-policy',
    COOKIE_POLICY = '/cookie-policy',
    RESERVATIONS = '/reservations',
    CATERING = '/catering',
    EVENTS = '/events',
    ABOUT_US = '/about-us',
    LOCATION_AND_HOURS = '/location-and-hours',
    MENU = '/menu',
}

export enum GERMAN_STOREFRONT_PAGES {
    HOME = '/',
    IMPRESSUM = '/impressum',
    PRIVACY_POLICY = '/datenschutz',
    COOKIE_POLICY = '/cookie-richtlinie',
    RESERVATIONS = '/reservierungen',
    CATERING = '/catering',
    EVENTS = '/veranstaltungen',
    LOCATION_AND_HOURS = '/standort-und-oeffnungszeiten',
    MENU = '/speisekarte',
}

export enum MODAL_SIZES {
    XS = 'lg:w-3/12 w-screen mx-small',
    SM = 'lg:w-4/12 w-screen',
    MD = 'lg:w-1/2 w-screen',
    LG = 'lg:w-8/12 w-screen',
    XL = 'lg:w-5/6 w-screen',
}

export enum DB_MODELS {
    STORE = 'Store',
    SECTION = 'Section',
    HERO_SECTION = 'HeroSection',
    CUSTOMIZABLE_SECTION = 'CustomizableSection',
    GALLERY_SECTION = 'GallerySection',
    PRODUCT_SECTION = 'ProductSection',
    MENU_SECTION = 'MenuSection',
    MENU_SECTION_ITEM = 'MenuSectionItem',
    TIME_LINE_SECTION_ITEM = 'TimeLineSectionItem',
    HERO_SECTION_ITEM = 'HeroSectionItem',
    SEO = 'Seo',
    POPUP = 'Popup',
    PRODUCT = 'Product',
    PAGE = 'Page',
    THEME = 'Theme',
    GOOGLE_REVIEW_SECTION = 'GoogleReviewSection',
    COMPANY = 'Company',
    IMPRESSUM = 'Impressum',
}

export enum MEDIA_TAGS {
    GALLERY = 'gallery',
    LOGO = 'logo',
    FLOATING_ICON = 'floating_icon',
    IMAGE = 'image',
    IMAGES = 'images',
    VIDEOS = 'videos',
    PDF = 'pdf',
    CUSTOM_FONTS = 'custom_fonts',
    FIRST_IMAGE = 'first_image',
    SECOND_IMAGE = 'second_image',
    THIRD_IMAGE = 'third_image',
    FIRST = 'first',
    SECOND = 'second',
    FOOTER = 'footer',
}

export enum LINK_IDENTIFIERS {
    FIRST = 'first',
    SECOND = 'second',
    THIRD = 'third',
    RESERVATION = 'reservation',
    CATERING = 'catering',
}

export enum MEDIA_TYPES {
    IMAGE = 'image',
    VIDEO = 'video',
    PDF = 'pdf',
    FONT = 'font',
    DOCUMENT = 'document',
}

export enum MEDIA_EXTENSION {
    JSON = 'json',
}

export enum LINK_TYPES {
    PAGE = 'page',
    ANCHOR_TAG = 'anchor_tag',
    VARIABLE = 'variable',
}

export enum LINK_TARGET_TYPES {
    CURRENT_TAB = '',
    NEW_TAB = '_blank',
}

export enum POPUP_TYPES {
    DEFAULT = 'default',
    PROMO_CODE = 'promo_code',
}

export enum LANGUAGES {
    DE = 'de',
    EN = 'en',
}

export enum CURRENCY_TYPES {
    EURO = 'EUR',
    POUND = 'GBP',
    DOLLAR = 'USD',
}

export enum SECTION_IDENTIFIERS {
    HERO = 'hero',
    ORDER_ONLINE = 'order_online',
    ABOUT_US = 'about_us',
    INGREDIENTS = 'ingredients',
    PASSION = 'passion',
}

export enum TEMPLATE_TYPES {
    STANDARD = 'standard',
    ELEGANT = 'elegant',
    MODERN = 'modern',
    SIMPLE = 'simple',
    COOL = 'cool',
    BOLD = 'bold',
    ASIAN = 'asian',
    MINIMALIST = 'minimalist',
    PERSIAN = 'persian',
    VIBRANT = 'vibrant',
    CHIC = 'chic',
}

export enum CONTRAST_MODE {
    LIGHT = 'light',
    DARK = 'dark',
}

export enum BUTTON_SIZE {
    SMALL = 'small',
    NORMAL = 'normal',
    LARGE = 'large',
}

export enum GOOGLE_REVIEW_SECTION_TYPES {
    ONE = 1,
    TWO = 2,
    THREE = 3,
}

export enum STORE_VARIABLES {
    ONLINE_SHOP = 'online_shop',
    INSTAGRAM = 'instagram',
    YOUTUBE = 'youtube',
    FACEBOOK = 'facebook',
    TIK_TOK = 'tik_tok',
    YELP = 'yelp',
}

export enum BUTTON_VARIANT {
    PRIMARY_FILL = 'primary_fill',
    SECONDARY_FILL = 'secondary_fill',
    LIGHT_OUTLINE = 'light_outline',
    DARK_OUTLINE = 'dark_outline',
}

export enum FONT_WEIGHT_TYPES {
    LIGHT = 'light',
    REGULAR = 'regular',
    MEDIUM = 'medium',
    SEMI_BOLD = 'semi_bold',
    BOLD = 'bold',
}

export enum PAGE_LOADING_INDICATOR_TYPES {
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FOUR = 4,
}

export enum BORDER_WIDTH_TYPES {
    NONE = 'none',
    LIGHT = 'light',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export enum BORDER_RADIUS_TYPES {
    NONE = 'none',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    FULL = 'full',
}

export enum NAVIGATION_BAR_TYPES {
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FOUR = 4,
    FIVE = 5,
    SIX = 6,
    SEVEN = 7,
}

export enum NAVIGATION_BAR_POSITION {
    FIXED = 'fixed',
    ABSOLUTE = 'absolute',
}

export enum TEXT_SIZE_TYPES {
    SMALL = 'small',
    MINI = 'mini',
    TINY = 'tiny',
    NANO = 'nano',
}

export enum COLOR_VARIABLES {
    PRIMARY_COLOR = 'primary_color',
    SECONDARY_COLOR = 'secondary_color',
    HEADER_COLOR = 'header_color',
    DESCRIPTION_COLOR = 'description_color',
    DARK_TEXT_COLOR = 'dark_text_color',
    LIGHT_TEXT_COLOR = 'light_text_color',
    BACKGROUND_COLOR = 'background_color',
    SECONDARY_BACKGROUND_COLOR = 'secondary_background_color',
    BORDER_COLOR = 'border_color',
    CARD_COLOR = 'card_color',
}

export enum LOCATION_TRACKER_SECTION_TYPES {
    ONE = 1,
    TWO = 2,
}

export enum OPENING_HOURS_TYPES {
    ONE = 1,
    TWO = 2,
    THREE = 3,
}

export enum RESERVATION_BUTTON_POSITION {
    STANDARD = 'standard',
    FIXED_ON_SIDE = 'fixed_on_side',
}

export enum STORE_SETUP_STATUS_TYPES {
    STORE_SETUP_FAILED = -1,
    STORE_SETUP_COMPLETED = 0,
    CONTENT_CREATION_COMPLETED = 1,
    LAYOUT_CREATION_COMPLETED = 2,
    GALLERY_UPLOAD_COMPLETED = 3,
}

export enum FOOTER_TYPES {
    ONE = 1,
    TWO = 2,
    THREE = 3,
}

export enum NEWS_BANNER_TYPES {
    ONE = 1,
    TWO = 2,
}

export enum HORIZONTAL_ALIGNMENT_TYPES {
    FULL = 'full',
    LEFT = 'left',
    RIGHT = 'right',
    CENTER = 'center',
}

export enum VERTICAL_ALIGNMENT_TYPES {
    FULL = 'full',
    TOP = 'top',
    CENTER = 'center',
    BOTTOM = 'bottom',
}

export enum LOTTIE_ASPECT_RATIOS {
    COVER = 'xMidYMid slice',
    CONTAIN = 'xMidYMid meet',
}

export enum SECTION_STYLE_ATTRIBUTES {
    HEADER_COLOR = 'header_color',
    DESCRIPTION_COLOR = 'description_color',
    BACKGROUND_COLOR = 'background_color',
    IS_SKEW = 'is_skew',
}

export enum PROMO_CODE_DISCOUNT_TYPES {
    FIXED_AMOUNT = 'fixed_amount',
    PERCENTAGE = 'percentage',
}

export enum PROMO_CODE_TYPES {
    ONE_TIME_PROMO_CODE = 'single_use',
    MULTI_PROMO_CODE = 'multi_use',
}

export enum PROMO_CODE_TAGS {
    ONE_TIME_PROMO_CODE = 'one_time',
}
