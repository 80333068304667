import { Button, Card, Form, Modal, Skeleton, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import collect from 'collect.js';
import { MediaInterface, PopupInterface, PromoCodeSettingInterface } from '../../../../exports/Interfaces';
import PopupManager from '../../../../services/api/PopupManager';
import { DB_MODELS, MEDIA_TAGS, POPUP_TYPES, STOREFRONT_PAGES } from '../../../../exports/Enums';
import MediaManager from '../../../../services/api/MediaManager';
import SelectedMediaList from '../../../media/SelectedMediaList';
import useScreenType from '../../../../hooks/useScreenType';
import useModals from '../../../../hooks/useModals';
import InitialDataManager from '../../../../services/api/InitialDataManager';
import useRdxStore from '../../../../hooks/useRdxStore';
import MarketingManager from '../../../../services/api/marketing/MarketingManager';

function LabelValue({ label, children }: { label: string; children: any }): JSX.Element | null {
    return children ? (
        <div className="flex justify-between items-center gap-2 mb-small">
            <h6 className="mini semibold">{label}</h6>
            <p className="text-right mini">{children}</p>
        </div>
    ) : null;
}

function Popup(): JSX.Element | null {
    const { popupModal, setPopupModal } = useModals();

    const { open } = popupModal;

    const popup = popupModal?.popup;

    const { isDesktop } = useScreenType();

    const { store } = useRdxStore();

    const isUpdate = !!popup;

    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(true);

    const [mediaList, setMediaList] = useState<MediaInterface[]>(popup?.image ? [popup?.image] : []);

    const [promoCodeSetting, setPromoCodeSetting] = useState<PromoCodeSettingInterface | null>(null);

    const getPromoCodeSetting = async (): Promise<void> => {
        setLoading(true);
        const { success, response } = await MarketingManager.getPromoCodeSetting(store?.franchise_slug);
        setLoading(false);

        if (success && response?.data?.data) {
            setPromoCodeSetting(response.data.data);
        }
    };

    useEffect(() => {
        getPromoCodeSetting();
    }, []);

    const onFinish = async (): Promise<void> => {
        setLoading(true);

        const data: Partial<PopupInterface> = {
            type: POPUP_TYPES.PROMO_CODE,
            page_id: collect(store?.pages).firstWhere('href', STOREFRONT_PAGES.HOME)?.id,
        };

        let request;
        if (typeof popup?.id === 'number') {
            request = await PopupManager.put(popup?.id, data);
        } else {
            request = await PopupManager.post(data);
        }

        const { response, success } = request;

        if (success && response?.data?.data) {
            await MediaManager.post(DB_MODELS.POPUP, response?.data?.data?.id, mediaList, MEDIA_TAGS.IMAGES, '');

            await InitialDataManager.get();
        }

        setLoading(false);

        if (success) {
            setPopupModal({ open: false });
            if (popupModal?.onSuccess) {
                popupModal.onSuccess();
            }
        }
    };

    return (
        <Modal
            title="Promo Code"
            open={open}
            onCancel={() => setPopupModal({ open: false })}
            width={isDesktop ? window.innerWidth * 0.3 : window.innerWidth}
            okText="Save"
            onOk={onFinish}
        >
            <Form name="basic" onFinish={onFinish} layout="vertical" initialValues={popup ?? {}} form={form}>
                {loading ? (
                    <Skeleton active />
                ) : (
                    <>
                        <div className="w-full my-small">
                            <SelectedMediaList
                                uploadCount={1}
                                defaultMediaList={popup?.image ? [popup?.image] : []}
                                mediaList={mediaList}
                                setMediaList={setMediaList}
                            />
                        </div>
                        <Card
                            title="Settings"
                            extra={
                                <Button
                                    icon={<EditOutlined />}
                                    href={`${
                                        import.meta.env.VITE_ADMIN_DASHBOARD_URL
                                    }/marketing/promo-codes?tab=email_sign_up`}
                                    target="_blank"
                                />
                            }
                        >
                            <LabelValue label="tag">
                                <Tag>{promoCodeSetting?.tag}</Tag>
                            </LabelValue>
                            <LabelValue label="Discount Type">
                                <Tag>{promoCodeSetting?.discount_type}</Tag>
                            </LabelValue>
                            <LabelValue label="Quantity">{promoCodeSetting?.quantity}</LabelValue>
                            {promoCodeSetting?.components?.map((component) => (
                                <>
                                    <LabelValue label="Discount">{component?.discount}%</LabelValue>
                                    <LabelValue label="Usage threshold">{component?.usage_threshold}</LabelValue>
                                    <LabelValue label="Max discount">{component?.max_discount}</LabelValue>
                                </>
                            ))}
                        </Card>
                    </>
                )}
            </Form>
        </Modal>
    );
}

export default Popup;
