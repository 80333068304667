import { useState } from 'react';
import { Form, Input, Button, Modal, Tag } from 'antd';
import collect from 'collect.js';
import PageManager from '../../services/api/page/PageManager';
import { PageDataInterface } from '../../exports/Interfaces';
import useRdxStore from '../../hooks/useRdxStore';
import { GERMAN_STOREFRONT_PAGES, STOREFRONT_PAGES } from '../../exports/Enums';

interface Props {
    open: boolean;
    toggle: (open?: boolean) => void;
}

function PageModal(props: Props): JSX.Element | null {
    const { open, toggle } = props;

    const { store } = useRdxStore();

    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(false);

    const onSave = async (values: PageDataInterface): Promise<void> => {
        await form.validateFields();
        setLoading(true);
        const { success } = await PageManager.post({
            ...values,
            navigation_item: {
                ...values?.navigation_item,
                is_hidden: false,
            },
            seo: {
                title: 'Page Title',
                description: 'Page Description',
            },
        });
        setLoading(false);
        if (success) {
            window.location.reload();
        }
    };

    const hrefValidator = (_: any, value: string): Promise<void> => {
        if (!value || value?.length < 1 || !value.match(/^\/.+/)) {
            return Promise.reject(new Error('Href must start with /'));
        }
        const englishValues = collect(STOREFRONT_PAGES)
            .values()
            .except([STOREFRONT_PAGES.ABOUT_US, STOREFRONT_PAGES.MENU]);
        const germanValues = collect(GERMAN_STOREFRONT_PAGES).values();
        const values = collect([...englishValues, ...germanValues]);

        if (values.contains(value)) {
            return Promise.reject(new Error(`Href is not allowed to match: ${values.implode(', ')}`));
        }

        return Promise.resolve();
    };

    return (
        <Modal title="Add Page" open={open} onCancel={() => toggle(false)} footer={null}>
            <Form form={form} name="basic" layout="vertical" initialValues={{ ...store }}>
                <p className="mini">* Href</p>
                <div className="mt-tiny mb-mini">
                    <p className="tiny text-brand-text-grey">
                        Type: <Tag>/menu </Tag>to create a menu page
                    </p>
                </div>
                <Form.Item
                    name="href"
                    rules={[
                        {
                            required: true,
                            validator: hrefValidator,
                        },
                    ]}
                >
                    <Input placeholder="/page" />
                </Form.Item>
                <Form.Item name={['navigation_item', 'name']} label="Name" rules={[{ required: true }]}>
                    <Input placeholder="Enter name" />
                </Form.Item>
                <Form.Item className="mb-none">
                    <Button type="primary" loading={loading} onClick={() => onSave(form.getFieldsValue())}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default PageModal;
