import { Button } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { PopupInterface } from '../../../../exports/Interfaces';
import useModals from '../../../../hooks/useModals';
import PopupManager from '../../../../services/api/PopupManager';
import ActionConfirmation from '../../../../components/popover/ActionConfirmation';
import { POPUP_TYPES } from '../../../../exports/Enums';

interface Props {
    popup: PopupInterface;
}

function PopupSection(props: Props): JSX.Element {
    const { popup } = props;

    const { setPopupModal } = useModals();

    const [deleteClicked, setDeleteCLicked] = useState<boolean>(false);

    const onEdit = (): void => {
        setPopupModal({ open: true, popup, type: popup.type ?? POPUP_TYPES.DEFAULT });
    };

    const onDelete = async (): Promise<void> => {
        PopupManager.delete(popup.id);
    };

    const renderContent = (): JSX.Element => {
        switch (popup.type) {
            case POPUP_TYPES.PROMO_CODE:
                return <p>Promo Code</p>;
            default:
                return (
                    <>
                        <p>{popup?.title}</p>
                        <p>{popup?.description}</p>
                    </>
                );
        }
    };

    return (
        <div className="border border-brand-inkGrey-grey_2 bg-background-inkWhite-white_0 rounded-md p-small shadow-section mb-small">
            <div className="flex justify-between">
                <h6>Modal</h6>
                <div className="flex">
                    <Button onClick={onEdit} icon={<EditOutlined />} className="mr-small" />
                    <ActionConfirmation
                        open={deleteClicked}
                        onOpenChange={setDeleteCLicked}
                        onConfirm={onDelete}
                        onCancel={() => setDeleteCLicked(false)}
                    >
                        <Button onClick={() => setDeleteCLicked(true)} icon={<DeleteOutlined />} />
                    </ActionConfirmation>
                </div>
            </div>
            {renderContent()}
        </div>
    );
}

export default PopupSection;
