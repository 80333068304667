import { useMemo, useState } from 'react';
import { Button, Table } from 'antd';
import { DeleteOutlined, EditOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import collect from 'collect.js';
import CompanyManager from '../../../services/api/CompanyManager';
import CompanyModal from '../../modals/CompanyModal';
import useRdxStore from '../../../hooks/useRdxStore';
import RestaurantInfoLayout from '../../layouts/RestaurantInfoLayout';
import { CompanyInterface } from '../../../exports/Interfaces';
import CompanyOrderManager from '../../../services/api/CompanyOrderManager';
import ReduxResource from '../../../services/resources/ReduxResource';
import actionCreators from '../../../store/actions';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}

function Row(props: RowProps): JSX.Element {
    const { style: rowStyle } = props;
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        // eslint-disable-next-line react/destructuring-assignment
        id: props['data-row-key'],
    });

    const style: React.CSSProperties = {
        ...rowStyle,
        transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
        transition,
        cursor: 'move',
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
}

function CompaniesForm(): JSX.Element {
    const { store } = useRdxStore();

    const [deletingCompanyId, setDeletingCompanyId] = useState<null | number>(null);

    const [showCompanyModal, setShowCompanyModal] = useState({
        open: false,
        company: null,
    });

    const deleteCompany = async (companyId: number): Promise<void> => {
        setDeletingCompanyId(companyId);
        await CompanyManager.delete(companyId);
        setDeletingCompanyId(null);
    };

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 1,
            },
        }),
    );

    const updateCompanyList = (list: CompanyInterface[]): void => {
        const data: number[] = collect(list)?.pluck('id')?.toArray();
        ReduxResource.dispatch(actionCreators.store.sortCompanies(data));
        CompanyOrderManager.put(data);
    };

    const onDragEnd = ({ active, over }: DragEndEvent): void => {
        try {
            if (active.id !== over?.id) {
                const activeIndex = (store?.companies || []).findIndex((i) => i.id === active.id);
                const overIndex = (store?.companies || []).findIndex((i) => i.id === over?.id);
                updateCompanyList(arrayMove(store?.companies || [], activeIndex, overIndex));
            }
        } catch (error) {}
    };

    const renderProductModal = useMemo(
        () =>
            showCompanyModal?.open ? (
                <CompanyModal
                    open={showCompanyModal?.open}
                    company={showCompanyModal?.company}
                    toggle={() =>
                        setShowCompanyModal({
                            open: false,
                            company: null,
                        })
                    }
                />
            ) : null,
        [showCompanyModal],
    );

    return (
        <RestaurantInfoLayout title="Companies">
            <div className="flex justify-end items-end">
                <Button
                    icon={<PlusOutlined />}
                    onClick={() =>
                        setShowCompanyModal({
                            open: true,
                            company: null,
                        })
                    }
                    type="primary"
                >
                    Add Company
                </Button>
            </div>
            <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                <SortableContext
                    items={(store?.companies || [])?.map((i) => i?.id)}
                    strategy={verticalListSortingStrategy}
                >
                    <Table
                        components={{
                            body: {
                                row: Row,
                            },
                        }}
                        rowKey="id"
                        columns={[
                            {
                                render: () => <MenuOutlined />,
                            },
                            {
                                title: 'Name',
                                dataIndex: 'name',
                            },
                            {
                                title: 'Google Place Id',
                                dataIndex: 'google_places_id',
                            },
                            {
                                title: 'Phone Number',
                                dataIndex: 'phone_number',
                            },
                            {
                                title: 'Edit',
                                render: (company) => (
                                    <Button onClick={() => setShowCompanyModal({ open: true, company })}>
                                        <EditOutlined />
                                    </Button>
                                ),
                            },
                            {
                                title: 'Delete',
                                render: (company) => (
                                    <Button
                                        onClick={() => deleteCompany(company?.id)}
                                        loading={company?.id === deletingCompanyId}
                                    >
                                        <DeleteOutlined />
                                    </Button>
                                ),
                            },
                        ]}
                        dataSource={store?.companies || []}
                        className="w-full overscroll-x-auto overflow-scroll"
                    />
                </SortableContext>
            </DndContext>
            {renderProductModal}
        </RestaurantInfoLayout>
    );
}

export default CompaniesForm;
