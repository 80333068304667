import { POPUP_TYPES } from '../../../exports/Enums';
import useModals from '../../../hooks/useModals';
import Default from './default';
import PromoCode from './promo-code';

function PopupModal(): JSX.Element | null {
    const { popupModal } = useModals();

    switch (popupModal?.type) {
        case POPUP_TYPES.PROMO_CODE:
            return <PromoCode />;
        default:
            return <Default />;
    }
}

export default PopupModal;
